import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import type { MakeLinkOptions } from '@tanstack/router'
import { useNavigate, useSearch } from '@tanstack/router'
import sha256 from 'crypto-js/sha256'
import dayjs from 'dayjs'
import {
  type ChangeEvent,
  type FormEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

import popup from '../../assets/common/popup.webp'
import useBlazeSlider from '../../hooks/useBlazeSlider'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { UserInfo } from '../../types/user'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { getDeviceInfo } from '../../utils/device'
import { getPublicAuth } from '../../utils/getPublicAuth'
import { encryptUser } from '../../utils/user'
import { BannerPopupModal, Spinner } from '../common'
import { TextInput } from '../register'
import DuplicateAccountModal from './duplicate-account-modal'
import Modal from './modal'
import RequestPasswordResetModal from './request-password-reset-modal'
import SocialMediaAuth from './social-media-auth'

const container = css`
  background-color: var(--secondary);
  border: none;
  padding: 20px 20px 15px;
  border-radius: 10px;
  width: calc(100% - 32px);
  max-height: calc(100vh - 32px);
  overflow-y: auto;
`

const carouselCss = css`
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  @media (min-width: 1200px) {
    margin: 10px auto 16px;
  }
`

const Checkbox = styled.label`
  position: relative;
  color: var(--secondary-txt);
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 16px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins';
  &.checked {
    span {
      &:after {
        opacity: 1;
      }
    }
  }
  input {
    display: none;
  }
  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: var(--primary);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: var(--primary-btn-bg);
      opacity: 0;
      transition: all 0.2s ease-in-out;
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }
`

const Button = styled.button`
  display: block;
  text-align: center;
  font-size: 18px;
  padding: 12px 24px;
  background-color: var(--highlight);
  color: white;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  &:disabled {
    background-color: #657f8c;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Text = styled.p`
  font-size: 14px;
  color: #1c112b;
  margin-bottom: 5px;
  text-align: center;
  font-family: 'Poppins';
  margin-top: 16px;
  &.forgot {
    color: var(--highlight);
    cursor: pointer;
    text-decoration: underline;
  }
`

const Banner = styled.img`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  border-radius: 6px;
  img {
    width: 100%;
    border-radius: 10px;
  }
`

const ErrorMessage = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 64, 88);
  width: 100%;
  margin-bottom: 10px;
  padding: 2px 0;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Divider = styled.p`
  font-size: 12px;
  font-family: 'Poppins';
  color: var(--secondary-txt);
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 12px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: calc(50% - 24px);
    height: 1px;
    background-color: var(--secondary-txt);
    top: 8px;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`

const Header = styled.div`
  background-color: var(--primary);
  padding: 0 20px;
  margin: -20px -20px 20px;
  border-radius: 20px 20px 0 0;
  justify-content: flex-end;
`

const RegBtn = styled.button`
  padding: 10px 0;
  font-family: 'Poppins';
  font-size: 14px;
  color: var(--highlight);
`

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
  gap: 8px;
`

const Dot = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transition: all 0.2s linear;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? 'var(--highlight)' : '#888'};
`

const RelativeContainer = styled.div`
  position: relative;
`

const Terms = styled.a`
  color: #fff;
  text-decoration: underline;
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
`

const LoginModal = () => {
  const { t } = useTranslation(['login', 'walletManagement', 'common'])
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { redirectUrl } = useSearch() as { redirectUrl?: string }
  const setUserStore = useBoundStore(state => state.user.setUser)
  const b1 = useBoundStore(state => state.settings.login_banner1)
  const b2 = useBoundStore(state => state.settings.login_banner1)
  const b3 = useBoundStore(state => state.settings.login_banner3)
  const b4 = useBoundStore(state => state.settings.login_banner4)
  const b5 = useBoundStore(state => state.settings.login_banner5)
  const b6 = useBoundStore(state => state.settings.login_banner6)

  const enableSocialMediaAuth = useBoundStore(
    state => state.settings.social_media_auth,
  )
  const modal = useModal()
  const bannerPopup = useModal(BannerPopupModal)
  const requestPasswordResetModal = useModal(RequestPasswordResetModal)
  const duplicateModal = useModal(DuplicateAccountModal)
  const navigate = useNavigate()
  const [user, setUser] = useState({
    username: '',
    password: '',
  })
  const [isRemember, setIsRemember] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState('')

  const banners = useMemo(() => {
    return [b1, b2, b3, b4, b5, b6].filter(banner => !!banner.value)
  }, [b1, b2, b3, b4, b5, b6])

  const { elRef, activeKey, slideTo } = useBlazeSlider({
    all: {
      enableAutoplay: true,
      slidesToShow: 1,
    },
  })

  useEffect(() => {
    if (modal.visible) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login_form_open',
          event_category: 'login',
          event_action: 'click_open',
          event_label: 'Open Login Form',
        },
      })
    }
  }, [modal.visible])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSuccess = (info: UserInfo, status: number) => {
    // Register
    if (status === 2) {
      api.post('/conduct.php', {
        type: 'activate_game',
      })

      TagManager.dataLayer({
        dataLayer: {
          event: 'registration_success',
          event_category: 'register',
          event_action: 'action_complete',
          event_label: 'Registration Third Step',
        },
      })

      // FB
      api.fbGraph({
        data: [
          {
            action_source: 'website',
            event_id: 12342,
            event_name: 'CompleteRegistration',
            event_time: dayjs().unix(),
            user_data: {
              em: [info.email ? sha256(info.email).toString() : null],
              ph: [info.phone ? sha256(info.phone).toString() : null],
              client_user_agent: navigator.userAgent,
              external_id: [
                info.userID ? sha256(info.userID).toString() : null,
              ],
            },
            custom_data: info,
          },
        ],
      })
      api.fbGraph2({
        data: [
          {
            action_source: 'website',
            event_id: 12342,
            event_name: 'CompleteRegistration',
            event_time: dayjs().unix(),
            user_data: {
              em: [info.email ? sha256(info.email).toString() : null],
              ph: [info.phone ? sha256(info.phone).toString() : null],
              client_user_agent: navigator.userAgent,
              external_id: [
                info.userID ? sha256(info.userID).toString() : null,
              ],
            },
            custom_data: info,
          },
        ],
      })
      // Twitter
      twq('event', 'tw-olsug-oltxg', {
        email_address: sha256(info.email).toString(),
        phone_number: sha256(`+52${info.phone}`).toString(),
      })

      navigate({ to: '/' })
      closeModal(modal)
      return
    }
    // Login
    if (
      location.pathname.includes('/sorteos') ||
      location.pathname.includes('/sports')
    ) {
      location.reload()
    } else {
      if (redirectUrl) {
        navigate({ to: redirectUrl, search: {} } as MakeLinkOptions<'/'>)
        closeModal(modal)
        return
      }

      navigate({ to: '/' })
    }
    localStorage.removeItem('ads_info')
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_login',
        event_category: 'login',
        event_action: 'action_complete',
        event_label: 'Login Success',
        user_id: info.username,
      },
    })
    bannerPopup.show({
      imgUrl: popup,
      onClick: () => window.open('https://wa.me/qr/WKSBEZZYNOOFP1'),
    })
    closeModal(modal)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!user.username || !user.password) return

    setError('')
    setIsLoading(true)

    const publicAuth = await getPublicAuth()

    const reqBody = {
      ...user,
      username: user.username.toLowerCase(),
      submit_type: 'login',
      meta: getDeviceInfo(),
      auth: publicAuth,
    }

    const response = await api.post<ApiResponse<UserInfo>>(
      '/center.php',
      reqBody,
    )

    const { status, info } = response.data

    if (status === 1) {
      const privateAuth = await api.post<
        ApiResponse<string | { auth: string; expired_time: number }>
      >('/link.php', {
        username: info.username,
        password: user.password,
        auth: publicAuth,
      })
      if (
        privateAuth.data.status === 1 &&
        typeof privateAuth.data.info !== 'string'
      ) {
        localStorage.setItem('privateAuth', privateAuth.data.info.auth)
      } else {
        if (typeof privateAuth.data.info === 'string') {
          setError(privateAuth.data.info)
        }
        setIsLoading(false)
        return
      }
      encryptUser({ username: info.username, password: user.password })
      setUserStore(info)
      onSuccess(info, 1)
    } else if (status === 2) {
      if (typeof info === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        navigate({ to: info })
        closeModal(modal)
      }
    } else if (status === 3) {
      duplicateModal.show()
      closeModal(modal)
    } else {
      if (typeof info === 'string') setError(info as unknown as string)
    }
    setIsLoading(false)
  }

  return (
    <Modal id="log-in-modal" containerStyle={container}>
      <Header className="flx-btw-ctr">
        <RegBtn
          onClick={() => {
            navigate({ to: '/register' })
            closeModal(modal)
          }}>
          {t('common:register').toUpperCase()}
        </RegBtn>
      </Header>
      <RelativeContainer>
        <DotContainer>
          {banners.map((_banner, key) => (
            <Dot
              key={key}
              isActive={key === activeKey}
              onClick={() => slideTo(key)}
            />
          ))}
        </DotContainer>
        <div className={'blaze-slider ' + carouselCss} ref={elRef}>
          <div className="blaze-container">
            <div className="blaze-track-container">
              <div className="blaze-track">
                {banners.map(banner => (
                  <RelativeContainer
                    key={banner.click_url}
                    className="keen-slider__slide">
                    {banner.value && <Banner src={banner.value} alt="banner" />}
                    {banner.click_url && (
                      <Terms href={banner.click_url} target="blank">
                        {t('terms', { ns: 'homeV2' })}
                      </Terms>
                    )}
                  </RelativeContainer>
                ))}
              </div>
            </div>
          </div>
        </div>
      </RelativeContainer>
      <form onSubmit={onSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <TextInput
          label={t('username', { ns: 'login' })}
          placeholder={`${t('example')}:Raul678`}
          value={user.username}
          name="username"
          onChange={onChange}
        />
        <TextInput
          label={t('password', { ns: 'login' })}
          isPassword
          value={user.password}
          name="password"
          onChange={onChange}
        />
        <Checkbox className={isRemember ? 'checked' : ''}>
          {t('remember-me')}
          <input
            type="checkbox"
            checked={isRemember}
            onChange={e => setIsRemember(e.target.checked)}
          />
          <span />
        </Checkbox>
        <div className="flx-ctr">
          {isLoading ? (
            <Spinner spinnerSize={39} />
          ) : (
            <Button style={{ margin: '0 auto' }} type="submit">
              {t('login')}
            </Button>
          )}
        </div>
      </form>
      <Divider>{t('common:or')}</Divider>
      {enableSocialMediaAuth.value === 1 && (
        <SocialMediaAuth callback={onSuccess} page="Login" />
      )}
      <Text
        className="forgot"
        onClick={() => {
          requestPasswordResetModal.show()
          closeModal(modal)
        }}>
        {t('forgot-password')}
      </Text>
    </Modal>
  )
}

export default NiceModal.create(LoginModal)
