import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'

import closeModal from '../../utils/closeModal'
import Icon from '../icon'
import Modal from './modal'

const container = css`
  max-width: 500px;
  padding: 0px;
  border-color: none;
  border: none;
  display: flex;
  background-color: transparent;
`

const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  @media (min-width: 1200px) {
    border-radius: 20px;
  }
`

const Close = styled.button`
  position: absolute;
  top: -35px;
  right: -5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  svg {
    width: 12px;
    height: 12px;
    fill: #444;
  }
`

interface BannerPopupModalProps {
  imgUrl: string
  onClick?: () => void
}

const BannerPopupModal = ({ imgUrl, onClick }: BannerPopupModalProps) => {
  const modal = useModal()

  return (
    <Modal id="banner-popup-modal" containerStyle={container} zIndex={340}>
      <Close className="flx-ctr" onClick={() => closeModal(modal)}>
        <Icon id="close" />
      </Close>
      <Image
        src={imgUrl}
        alt="Banner"
        onClick={onClick ? onClick : () => closeModal(modal)}
      />
    </Modal>
  )
}

export default NiceModal.create(BannerPopupModal)
